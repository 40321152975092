import React from 'react';
import { StyleSheet, css } from 'aphrodite';

import { black, white } from 'shared/colors';
import { BREAKPOINTS } from 'shared/constants';
import SEO from 'components/SEO/SEO';

interface Props {
  location: { pathname: string };
}

const PrivacyPage = ({ location: { pathname } }: Props) => {
  return (
    <>
      <SEO title="Privacy Policy | MONOGRAM" pathname={pathname} />
      <main className={css(styles.container)}>
        <h1 className={css(styles.header)}>Privacy Policy</h1>
        <div className={css(styles.textContainer)}>
          <p className={css(styles.text)}>
            <strong>Effective Date:</strong> 10/20/20
          </p>
          <p className={css(styles.p)}>
            Caliva and its affiliated entities (<strong>“Company”</strong> or
            <strong> “We”</strong>) respect your privacy and are committed to
            protecting it through our compliance with this policy. This policy
            describes the types of information we may collect from you or that
            you may provide to us when you visit our websites that link to this
            policy (our “<strong>Website</strong>”) and our practices for
            collecting, using, maintaining, protecting, and disclosing that
            information. We adopt this notice to comply with the California
            Consumer Privacy Act of 2018 (CCPA) and any terms defined in the
            CCPA have the same meaning when used in this Notice.
          </p>
          <h2 className={css(styles.h2)}>
            <strong>1. INFORMATION WE COLLECT</strong>
          </h2>
          <p className={css(styles.p)}>
            We collect information that identifies, relates to, describes,
            references, is reasonably capable of being associated with, or could
            reasonably be linked, directly or indirectly, with a particular
            consumer, household, or device (“
            <strong>personal information</strong>”). Personal information does
            not include:
          </p>
          <ul className={css(styles.ul)}>
            <li>Publicly available information from government records.</li>
            <li>De-identified or aggregated consumer information.</li>
          </ul>
          <p className={css(styles.p)}>
            In particular, we have collected the following categories of
            personal information from our consumers within the last 12 months:
          </p>
          <br />
          <section>
            <div>
              <div style={{ padding: '0 20px' }}>
                <table
                  style={{
                    fontFamily: 'Titling Gothic FB Normal Regular',
                    margin: '0 auto',
                    maxWidth: 865,
                    fontSize: 14,
                    border: '1px solid white',
                  }}
                >
                  <tbody>
                    <tr>
                      <th className={css(styles.tableBorder)}>Category</th>
                      <th className={css(styles.tableBorder)}>Examples</th>
                      <th className={css(styles.tableBorder)}>Collected</th>
                    </tr>
                    <tr>
                      <td className={css(styles.tableBorder)}>
                        A. Identifiers.
                      </td>
                      <td className={css(styles.tableBorder)}>
                        A real name, alias, postal address, unique personal
                        identifier, online identifier, Internet Protocol
                        address, email address, account name, Social Security
                        number, driver’s license number, passport number, or
                        other similar identifiers.
                      </td>
                      <td
                        className={css(styles.centerText, styles.tableBorder)}
                      >
                        YES
                      </td>
                    </tr>
                    <tr>
                      <td className={css(styles.tableBorder)}>
                        B. Personal information categories listed in the
                        California Customer Records statute (Cal. Civ. Code §
                        1798.80(e)).
                      </td>
                      <td className={css(styles.tableBorder)}>
                        A name, signature, Social Security number, physical
                        characteristics or description, address, telephone
                        number, passport number, driver’s license or state
                        identification card number, insurance policy number,
                        education, employment, employment history, bank account
                        number, credit card number, debit card number, or any
                        other financial information, medical information, or
                        health insurance information.
                      </td>
                      <td
                        className={css(styles.centerText, styles.tableBorder)}
                      >
                        YES
                      </td>
                    </tr>
                    <tr>
                      <td className={css(styles.tableBorder)}>
                        C. Protected classification characteristics under
                        California or federal law.
                      </td>
                      <td className={css(styles.tableBorder)}>
                        Age (40 years or older), race, color, ancestry, national
                        origin, citizenship, religion or creed, marital status,
                        medical condition, physical or mental disability, sex
                        (including gender, gender identity, gender expression,
                        pregnancy or childbirth and related medical conditions),
                        sexual orientation, veteran or military status, genetic
                        information (including familial genetic information).
                      </td>
                      <td
                        className={css(styles.centerText, styles.tableBorder)}
                      >
                        YES
                      </td>
                    </tr>
                    <tr>
                      <td className={css(styles.tableBorder)}>
                        D. Commercial information.
                      </td>
                      <td className={css(styles.tableBorder)}>
                        Records of personal property, products or services
                        purchased, obtained, or considered, or other purchasing
                        or consuming histories or tendencies.
                      </td>
                      <td
                        className={css(styles.centerText, styles.tableBorder)}
                      >
                        YES
                      </td>
                    </tr>
                    <tr>
                      <td className={css(styles.tableBorder)}>
                        E. Biometric information.
                      </td>
                      <td className={css(styles.tableBorder)}>
                        Genetic, physiological, behavioral, and biological
                        characteristics, or activity patterns used to extract a
                        template or other identifier or identifying information,
                        such as, fingerprints, faceprints, and voiceprints, iris
                        or retina scans, keystroke, gait, or other physical
                        patterns, and sleep, health, or exercise data.
                      </td>
                      <td
                        className={css(styles.centerText, styles.tableBorder)}
                      >
                        NO
                      </td>
                    </tr>
                    <tr>
                      <td className={css(styles.tableBorder)}>
                        F. Internet or other similar network activity.
                      </td>
                      <td className={css(styles.tableBorder)}>
                        Browsing history, search history, information on a
                        consumer’s interaction with a website, application, or
                        advertisement.
                      </td>
                      <td
                        className={css(styles.centerText, styles.tableBorder)}
                      >
                        YES
                      </td>
                    </tr>
                    <tr>
                      <td className={css(styles.tableBorder)}>
                        G. Geolocation data.
                      </td>
                      <td className={css(styles.tableBorder)}>
                        Physical location or movements.
                      </td>
                      <td
                        className={css(styles.centerText, styles.tableBorder)}
                      >
                        YES
                      </td>
                    </tr>
                    <tr>
                      <td className={css(styles.tableBorder)}>
                        H. Sensory data
                      </td>
                      <td className={css(styles.tableBorder)}>
                        Audio, electronic, visual, thermal, olfactory, or
                        similar information.
                      </td>
                      <td
                        className={css(styles.centerText, styles.tableBorder)}
                      >
                        NO
                      </td>
                    </tr>
                    <tr>
                      <td className={css(styles.tableBorder)}>
                        I. Professional or employment-related information.
                      </td>
                      <td className={css(styles.tableBorder)}>
                        Current or past job history or performance evaluations.
                      </td>
                      <td
                        className={css(styles.centerText, styles.tableBorder)}
                      >
                        YES
                      </td>
                    </tr>
                    <tr>
                      <td className={css(styles.tableBorder)}>
                        J. Non-public education information (per the Family
                        Educational Rights and Privacy Act (20 U.S.C. Section
                        1232g, 34 C.F.R. Part 99)).
                      </td>
                      <td className={css(styles.tableBorder)}>
                        Education records directly related to a student
                        maintained by an educational institution or party acting
                        on its behalf, such as grades, transcripts, class lists,
                        student schedules, student identification codes, student
                        financial information, or student disciplinary records.
                      </td>
                      <td
                        className={css(styles.centerText, styles.tableBorder)}
                      >
                        NO
                      </td>
                    </tr>
                    <tr>
                      <td className={css(styles.tableBorder)}>
                        K. Inferences drawn from other personal information.
                      </td>
                      <td className={css(styles.tableBorder)}>
                        Profile reflecting a person’s preferences,
                        characteristics, psychological trends, predispositions,
                        behavior, attitudes, intelligence, abilities, and
                        aptitudes.
                      </td>
                      <td
                        className={css(styles.centerText, styles.tableBorder)}
                      >
                        YES
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          <br />
          <p className={css(styles.p)}>
            We obtain the categories of personal information listed above from
            the following categories of sources:
          </p>
          <ul className={css(styles.ul)}>
            <li>
              Directly from you. For example, from forms you complete or
              products and services you purchase.
            </li>
            <li>
              Indirectly from you. For example, from observing your actions on
              our Website.
            </li>
          </ul>
          <h2 className={css(styles.h2)}>2. USE OF PERSONAL INFORMATION</h2>
          <p className={css(styles.p)}>
            We may use or disclose the personal information we collect for one
            or more of the following purposes:
          </p>

          <ul className={css(styles.ul)}>
            <li>
              To fulfill or meet the reason you provided the information. For
              example, if you share your name and contact information to ask a
              question about our products or services, we will use that personal
              information to respond to your inquiry. If you provide your
              personal information to purchase a product or service, we will use
              that information to process your payment and facilitate delivery.
              We may also save your information to facilitate new product orders
              or process returns.
            </li>
            <li>
              To create, maintain, customize, and secure your account with us.
            </li>
            <li>
              To process your requests, purchases, transactions, and payments.
            </li>
            <li>
              To provide you with support, respond to your inquiries, and
              address your concerns and monitor and improve our responses.
            </li>
            <li>
              To personalize your Website experience and to deliver content,
              product, and service offerings relevant to your interests,
              including targeted offers via email or text message (with your
              consent, where required by law).
            </li>
            <li>
              To help maintain the safety, security, and integrity of our
              Website, products and services, databases and other technology
              assets.
            </li>
            <li>
              For testing, research, analysis, and product development,
              including to develop and improve our Website, products, and
              services.
            </li>
            <li>
              To respond to law enforcement requests and as required by
              applicable law, court order, or governmental regulations.
            </li>
            <li>
              As described to you when collecting your personal information or
              as otherwise set forth in the CCPA.
            </li>
            <li>
              To evaluate or conduct a merger, divestiture, restructuring,
              reorganization, dissolution, or other sale or transfer of some or
              all of our assets, whether as a going concern or as part of
              bankruptcy, liquidation, or similar proceeding, in which personal
              information held by us about our Website users is among the assets
              transferred.
            </li>
          </ul>
          <p className={css(styles.p)}>
            We will not collect additional categories of personal information or
            use the personal information we collected for materially different,
            unrelated, or incompatible purposes without providing you notice.
          </p>
          <h2 className={css(styles.h2)}>3. SHARING PERSONAL INFORMATION</h2>
          <p className={css(styles.p)}>
            We may disclose your personal information to a third party for a
            business purpose. When we disclose personal information for a
            business purpose, we enter a contract that describes the purpose and
            requires the recipient to both keep that personal information
            confidential and not use it for any purpose except performing the
            contract.{' '}
          </p>
          <p className={css(styles.p)}>
            We share your personal information with the following categories of
            third parties:
          </p>
          <ul className={css(styles.ul)}>
            <li>Service providers.</li>
            <li>Affiliates</li>
            <li>Parent or subsidiary organizations</li>
          </ul>
          <h3 className={css(styles.h3)}>
            3.1.           Disclosures of Personal Information for a Business
            Purpose
          </h3>
          <p className={css(styles.p)}>
            In the preceding 12 months, Company has disclosed the following
            categories of personal information for a business purpose:
          </p>
          <ul className={css(styles.ul)}>
            <li>Category A: Identifiers</li>
            <li>
              Category B: California Customer Records personal information
              categories
            </li>
            <li>
              Category C: Protected classification characteristics under
              California or federal law
            </li>
            <li>Category D: Commercial information</li>
            <li>Category F: Internet or other similar network activity</li>
            <li>Category G: Geolocation data </li>
            <li>Category I: Professional or employment-related information</li>
            <li>
              Category K: Inferences drawn from other personal information
            </li>
          </ul>
          <p className={css(styles.p)}>
            We disclose your personal information for a business purpose to the
            following categories of third parties:
          </p>
          <ul className={css(styles.ul)}>
            <li>Service providers.</li>
            <li>Affiliates</li>
            <li>Parent or subsidiary organizations</li>
          </ul>
          <h3 className={css(styles.h3)}>
            3.2.          Sales of Personal Information
          </h3>
          <p className={css(styles.p)}>
            In the preceding 12 months, Company has not sold personal
            information.
          </p>
          <h2 className={css(styles.h2)}>4. YOUR RIGHTS AND CHOICES </h2>
          <p className={css(styles.p)}>
            The CCPA provides consumers (California residents) with specific
            rights regarding their personal information. This section describes
            your CCPA rights and explains how to exercise those rights.
          </p>
          <h3 className={css(styles.h3)}>
            4.1.          Access to Specific Information and Data Portability
            Rights
          </h3>
          <p className={css(styles.p)}>
            You have the right to request that we disclose certain information
            to you about our collection and use of your personal information
            over the past 12 months. Once we receive and confirm your verifiable
            consumer request (see{' '}
            <a
              className={css(styles.a)}
              href="https://www.gocaliva.com/privacy-policy#co_anchor_a585939_1"
            >
              Exercising Access, Data Portability, and Deletion Rights
            </a>
            ), we will disclose to you:
          </p>
          <ul className={css(styles.ul)}>
            <li>
              The categories of personal information we collected about you
            </li>
            <li>
              The categories of sources for the personal information we
              collected about you
            </li>
            <li>
              Our business or commercial purpose for collecting that personal
              information
            </li>
            <li>
              The categories of third parties with whom we share that personal
              information
            </li>
            <li>
              The specific pieces of personal information we collected about you
              (also called a data portability request)
            </li>
            <li>
              If we disclosed your personal information for a business purpose,
              disclosures identifying the personal information categories that
              each category of recipient obtained.
            </li>
          </ul>
          <h3 className={css(styles.h3)}>
            4.2.          Deletion Request Rights
          </h3>
          <p className={css(styles.p)}>
            You have the right to request that we delete any of your personal
            information that we collected from you and retained, subject to
            certain exceptions. Once we receive and confirm your verifiable
            consumer request (see{' '}
            <a
              className={css(styles.a)}
              href="https://www.gocaliva.com/privacy-policy#co_anchor_a585939_1"
            >
              Exercising Access, Data Portability, and Deletion Rights
            </a>
            ), we will delete (and direct our service providers to delete) your
            personal information from our records, unless an exception applies.
          </p>
          <p className={css(styles.p)}>
            We may deny your deletion request if retaining the information is
            necessary for us or our service providers to:
          </p>
          <ul className={css(styles.ul)}>
            <li>
              Complete the transaction for which we collected the personal
              information, provide a good or service that you requested, take
              actions reasonably anticipated within the context of our ongoing
              business relationship with you, fulfill the terms of a written
              warranty or product recall conducted in accordance with federal
              law, or otherwise perform our contract with you.
            </li>
            <li>
              Detect security incidents, protect against malicious, deceptive,
              fraudulent, or illegal activity, or prosecute those responsible
              for such activities.
            </li>
            <li>
              Debug products to identify and repair errors that impair existing
              intended functionality.
            </li>
            <li>
              Exercise free speech, ensure the right of another consumer to
              exercise their free speech rights, or exercise another right
              provided for by law.
            </li>
            <li>
              Comply with the California Electronic Communications Privacy Act
              (Cal. Penal Code § 1546 et. seq.).
            </li>
            <li>
              Engage in public or peer-reviewed scientific, historical, or
              statistical research in the public interest that adheres to all
              other applicable ethics and privacy laws, when the information’s
              deletion may likely render impossible or seriously impair the
              research’s achievement, if you previously provided informed
              consent.
            </li>
            <li>
              Enable solely internal uses that are reasonably aligned with
              consumer expectations based on your relationship with us.{' '}
            </li>
            <li>Comply with a legal obligation.</li>
            <li>
              Make other internal and lawful uses of that information that are
              compatible with the context in which you provided it.
            </li>
          </ul>
          <h3 className={css(styles.h3)}>
            4.3.          Exercising Access, Data Portability, and Deletion
            Rights
          </h3>
          <p className={css(styles.p)}>
            To exercise the access, data portability, and deletion rights
            described above, please submit a verifiable consumer request to us
            by either:
          </p>
          <ul className={css(styles.ul)}>
            <li>Calling us toll free at (888) 265-8627</li>
            <li>
              Visiting{' '}
              <a className={css(styles.a)} href="https://caliva.com/ccpa">
                CCPA Request Submission Form
              </a>
            </li>
            <li>
              For account holders, accessing the submission form link  from
              inside your account (instructions below).
            </li>
          </ul>
          <p className={css(styles.p)}>
            Only you, or someone legally authorized to act on your behalf, may
            make a verifiable consumer request related to your personal
            information. You may also make a verifiable consumer request on
            behalf of your minor child. To designate an authorized agent, you
            must provide a valid power of attorney under California’s Probate
            Code or other verifiable written documentation granting the agent
            permission to make the request.
          </p>
          <p className={css(styles.p)}>
            You may only make a verifiable consumer request for access or data
            portability twice within a 12-month period. The verifiable consumer
            request must:
          </p>
          <ul className={css(styles.ul)}>
            <li>
              Provide sufficient information that allows us to reasonably verify
              you are the person about whom we collected personal information or
              an authorized representative, which may include:
            </li>
            <li>
              For password-protected account holders, you can access our secure
              request form by logging into your account and using the submission
              form link.
            </li>
            <li>
              For non-account holders, using the{' '}
              <a className={css(styles.a)} href="https://caliva.com/ccpa">
                CCPA Request Submission Form
              </a>
               . You must provide at least two data points that we can match
              with the personal information we have collected about you.{' '}
            </li>
            <li>
              Describe your request with sufficient detail that allows us to
              properly understand, evaluate, and respond to it.
            </li>
          </ul>
          <p className={css(styles.p)}>
            We cannot respond to your request or provide you with personal
            information if we cannot verify your identity or authority to make
            the request and confirm the personal information relates to you.
          </p>
          <p className={css(styles.p)}>
            Making a verifiable consumer request does not require you to create
            an account with us. However, we do consider requests made through
            your password protected account more easily verifiable when the
            request relates to personal information associated with that
            specific account.
          </p>
          <p className={css(styles.p)}>
            We will only use personal information provided in a verifiable
            consumer request to verify the requestor’s identity or authority to
            make the request.
          </p>
          <h3 className={css(styles.h3)}>
            4.4.          Response Timing and Format
          </h3>
          <p className={css(styles.p)}>
            We endeavor to respond to a verifiable consumer request within 45
            days of its receipt. If we require more time (up to an additional 45
            days), we will inform you of the reason and extension period in
            writing.
          </p>
          <p className={css(styles.p)}>
            Any disclosures we provide will only cover the 12-month period
            preceding the verifiable consumer request’s receipt. The response we
            provide will also explain the reasons we cannot comply with a
            request, if applicable. For data portability requests, we will
            select a format to provide your personal information that is readily
            useable and should allow you to transmit the information from one
            entity to another entity without hindrance.
          </p>
          <p className={css(styles.p)}>
            We do not charge a fee to process or respond to your verifiable
            consumer request unless it is excessive, repetitive, or manifestly
            unfounded. If we determine that the request warrants a fee, we will
            tell you why we made that decision and provide you with a cost
            estimate before completing your request.
          </p>
          <h2 className={css(styles.h2)}>5. NON-DISCRIMINATION</h2>
          <p className={css(styles.p)}>
            We will not discriminate against you for exercising any of your CCPA
            rights. Unless permitted by the CCPA, we will not:{' '}
          </p>
          <ul className={css(styles.ul)}>
            <li>Deny you goods or services.</li>
            <li>
              Charge you different prices or rates for goods or services,
              including through granting discounts or other benefits, or
              imposing penalties.
            </li>
            <li>
              Provide you a different level or quality of goods or services.
            </li>
            <li>
              Suggest that you may receive a different price or rate for goods
              or services or a different level or quality of goods or services.
            </li>
          </ul>
          <p className={css(styles.p)}>
            However, we may offer you certain financial incentives permitted by
            the CCPA that can result in different prices, rates, or quality
            levels. Any CCPA-permitted financial incentive we offer will
            reasonably relate to your personal information’s value and contain
            written terms that describe the program’s material aspects.
            Participation in a financial incentive program requires your prior
            opt in consent, which you may revoke at any time.{' '}
          </p>
          <h2 className={css(styles.h2)}>6. OTHER CALIFORNIA PRIVACY RIGHTS</h2>
          <p className={css(styles.p)}>
            The Company does not generally engage third parties for direct
            marketing purposes.  However, we provide notice here of California’s
            “Shine the Light” Law (Civil Code Section § 1798.83) permits users
            of our Website that are California residents to request certain
            information regarding our disclosure of personal information to
            third parties for their direct marketing purposes. To make such a
            request, please send an email with the subject “Shine the Light Law
            Request” to privacy@caliva.com or write us at Caliva, 1550 Leigh
            Ave, San Jose, CA 95125, Attn: Compliance – Shine the Light Law
            Request.{' '}
          </p>
          <h2 className={css(styles.h2)}>7. CHANGES TO OUR PRIVACY NOTICE</h2>
          <p className={css(styles.p)}>
            We reserve the right to amend this privacy notice at our discretion
            and at any time. When we make changes to this privacy notice, we
            will post the updated notice on the Website and update the notice’s
            effective date.{' '}
            <strong>
              Your continued use of our Website following the posting of changes
              constitutes your acceptance of such changes
            </strong>
            .
          </p>
          <h2 className={css(styles.h2)}>8. CONTACT INFORMATION</h2>
          <p className={css(styles.p)}>
            To make a CCPA request please use the URL below:
            <br />
            <a
              className={css(styles.a)}
              href="https://caliva.com/ccpa"
              target="'_blank'"
              rel="noopener noreferrer"
            >
              https://caliva.com/ccpa
            </a>
          </p>
          <p className={css(styles.p)}>
            If you have any questions or comments about this notice, the ways in
            which the Company collects and uses your information, your choices
            and rights regarding such use, or wish to exercise your rights under
            California law, please do not hesitate to contact us at:
          </p>
          <p className={css(styles.p)}>
            <strong>Email:</strong>{' '}
            <a className={css(styles.a)} href="mailto://privacy@caliva.com">
              privacy@caliva.com
            </a>
          </p>
          <p className={css(styles.p)}>
            <strong>Toll Free Phone</strong>: (888) 265-8627
          </p>
          <p className={css(styles.p)}>
            <strong>Websites</strong>: caliva.com, wellbycaliva.com,
            livezola.com, fununcle.com, allbadapple.com, drinksoulgrind.com and
            monogramcompany.com
          </p>
          <p className={css(styles.p)}>
            <strong>Postal Address</strong>:
          </p>
          <p className={css(styles.p)}>
            Caliva
            <br />
            1550 Leigh Ave <br />
            San Jose, CA 95125
          </p>
          <p className={css(styles.p)}>Attn: Compliance – Privacy Request</p>
        </div>
      </main>
    </>
  );
};

export default PrivacyPage;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: black,
    color: white,
    width: '100%',
    padding: '12rem 2rem 2rem',
  },
  accessibleLogo: {
    height: 150,
    width: 150,
    margin: '0 auto',
  },
  header: {
    fontFamily: 'Titling Gothic FB Narrow Standard',
    fontSize: 24,
    letterSpacing: 4,
    textTransform: 'uppercase',
    textAlign: 'center',
    margin: '2.5rem 0',
  },
  textContainer: {
    fontFamily: 'Titling Gothic FB Normal Regular',
    width: 700,
    margin: '0 auto',
    [BREAKPOINTS.MOBILE]: {
      width: 250,
    },
  },
  text: {
    marginBottom: '1.5rem',
    [BREAKPOINTS.MOBILE]: {
      marginBottom: '1rem',
    },
  },
  h2: {
    marginTop: '1rem',
    marginBottom: '1rem',
    fontSize: '1rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  h3: {
    fontSize: '0.9rem',
    marginBottom: '1rem',
    fontWeight: 'bold',
  },
  ul: {
    paddingLeft: '1rem',
    fontSize: 14,
  },
  p: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Titling Gothic FB Normal Regular',
  },
  a: {
    color: white,
  },
  centerText: {
    textAlign: 'center',
  },
  tableBorder: {
    border: '1px solid white',
    padding: 4,
  },
});
